import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { setupCache } from "axios-cache-adapter";

//ViewAir
// export const MAIN_URL = "https://chat-tis.com";
export const MAIN_URL = "https://chatis.info";
//GCP
// export const MAIN_URL = "https://chat-tis.com";

// export const MAIN_URL = "https://localhost:44311";
export const OGP_URL = "https://asia-northeast2-tokushukai-0001.cloudfunctions.net/chatis-ogp-maker";
export const API_URL = MAIN_URL + "/Home";
export const LOGIN_URL = MAIN_URL + "/Login";
export const STATIC_URL = MAIN_URL + "/Static";
export const TOKEN_URL = MAIN_URL + "/AccessToken";
export const HUB_URL = MAIN_URL + "/chatHub";
export const STAMP_URL = "https://storage.googleapis.com/chatis_bucket";
export const APP_ID = "bdaecca703874552900f75c30ab071e0";
export const CALENDAR_URL = "https://chatis-calendar.com";
export const SALARY_URL = "https://tk-salary.azurewebsites.net";
export const USER_SELECT_OPTION = ["ログイン可", "WEB給与", "PHR", "日報", "Eラーニング", "VIP", "安否作成"];

/* システム管理者 or 特定の病院 or 対象が一時ユーザー */
export const USER_BUTTON_OPTION_TEMP_USER = [
  "パスワード初期化",
  "QRコード印刷",
  "ロック解除",
  "電話番号初期化",
  "名前変更",
  "Email変更",
  "部署変更",
  "退職",
];
/* 上記以外は、基本的には退職できないようにする */
export const USER_BUTTON_OPTION_WITHOUT_LEAVE = [
  "パスワード初期化",
  "QRコード印刷",
  "ロック解除",
  "電話番号初期化",
  "名前変更",
  "Email変更",
  "部署変更",
];
export const ADMIN_BUTTON_OPTION = [
  "QRコード印刷プレビュー",
  "ロック解除",
  "ログイン可一括ON/OFF",
  "給与一括ON/OFF",
  "PHR一括ON/OFF",
  "日報一括ON/OFF",
  "Eラーニング一括ON/OFF",
];

export const USER_BELL_BUTTON_OPTION = ["参照", "削除"];
export const ALUMNI_BUTTON_OPTION = ["参照", "削除"];
export const GOOD_EMOJI = ["😊", "😂", "👍", "🧡", "ojigiman"];
export const jobOptions = [
  { label: "医師", value: "110" },
  { label: "歯科医師", value: "120" },
  { label: "助産師", value: "210" },
  { label: "保健師", value: "220" },
  { label: "看護師", value: "230" },
  { label: "准看護師", value: "240" },
  { label: "介護支援専門員Ⅰ", value: "310" },
  { label: "介護支援専門員Ⅱ", value: "311" },
  { label: "介護支援専門員Ⅲ", value: "312" },
  { label: "薬剤師", value: "320" },
  { label: "診療放射線技師", value: "330" },
  { label: "臨床検査技師", value: "340" },
  { label: "理学療法士", value: "350" },
  { label: "作業療法士", value: "351" },
  { label: "言語聴覚士", value: "352" },
  { label: "言語療法士", value: "353" },
  { label: "物療技師", value: "354" },
  { label: "臨床工学技士", value: "360" },
  { label: "透析技術員", value: "361" },
  { label: "管理栄養士", value: "370" },
  { label: "栄養士", value: "371" },
  { label: "歯科衛生士", value: "380" },
  { label: "歯科技工士", value: "381" },
  { label: "救急救命士", value: "383" },
  { label: "視能訓練士", value: "390" },
  { label: "その他医療技術", value: "395" },
  { label: "事務員", value: "410" },
  { label: "保育士", value: "440" },
  { label: "調理師", value: "510" },
  { label: "厨房員", value: "520" },
  { label: "介護福祉士", value: "530" },
  { label: "ﾎｰﾑﾍﾙﾊﾟｰ1級", value: "540" },
  { label: "ﾎｰﾑﾍﾙﾊﾟｰ2級", value: "541" },
  { label: "実務研修修了者", value: "542" },
  { label: "基礎研修修了者", value: "543" },
  { label: "初任者研修修了者", value: "544" },
  { label: "看護補助者", value: "550" },
  { label: "研究職", value: "610" },
  { label: "研修医", value: "999" },
];
const cache = setupCache({
  maxAge: 60 * 60 * 1000, //60分
  exclude: {
    // Store responses from requests with query parameters in cache
    query: false,
  },
});
export const api = axios.create({
  adapter: cache.adapter,
  baseURL: OGP_URL,
});

export class ExtendDateFnsUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}
export const custom_axios = async (urlstr) => {
  try {
    const result = await axios(urlstr, {
      headers: {
        honbuid: localStorage.getItem("honbuid"),
        apikey: localStorage.getItem("apikey"),
      },
    });
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_flutter2react = async (urlstr, honbuid, apikey) => {
  try {
    const result = await axios(urlstr, {
      headers: {
        honbuid: honbuid,
        apikey: apikey,
      },
    });
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_post = async (urlstr, body, headers = "") => {
  try {
    if (headers == "") {
      headers = {
        headers: {
          honbuid: localStorage.getItem("honbuid"),
          apikey: localStorage.getItem("apikey"),
        },
      };
    } else {
      headers.headers.honbuid = localStorage.getItem("honbuid");
      headers.headers.apikey = localStorage.getItem("apikey");
    }
    //console.log("headers", headers);
    const result = await axios.post(urlstr, body, headers);
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_post_flutter2react = async (urlstr, body, headers) => {
  try {
    //console.log("headers", headers);
    const result = await axios.post(urlstr, body, headers);
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const unescapeHTML = (str) => {
  return str
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&nbsp;/g, "");
};
